export const getSpacingTopClass = value => {
  let spacingTopClass = 'pt-6 pt-md-8 pt-lg-10';

  if ( typeof value.nzSettingSpacingTop !== 'undefined'  && value.nzSettingSpacingTop) {
    spacingTopClass = value.nzSettingSpacingTop?.toLowerCase();

    switch ( spacingTopClass ) {
      case 'none':
        spacingTopClass = 'pt-0';
        break;
      case 'small':
        spacingTopClass = 'pt-4 pt-md-5 pt-lg-7';
        break;
      case 'medium':
        spacingTopClass = 'pt-6 pt-md-8 pt-lg-10';
        break;
      case 'large':
        spacingTopClass = 'pt-8 pt-md-11 pt-lg-13';
        break;
    }
  }
  return spacingTopClass;
};

export const getSpacingBottomClass = value => {
  let spacingBottomClass = 'pb-6 pb-md-8 pb-lg-10';

  if ( typeof value.nzSettingSpacingBottom !== 'undefined' && value.nzSettingSpacingBottom) {
    spacingBottomClass = value.nzSettingSpacingBottom?.toLowerCase();

    switch ( spacingBottomClass ) {
      case 'none':
        spacingBottomClass = 'pb-0';
        break;
      case 'small':
        spacingBottomClass = 'pb-4 pb-md-5 pb-lg-7';
        break;
      case 'medium':
        spacingBottomClass = 'pb-6 pb-md-8 pb-lg-10';
        break;
      case 'large':
        spacingBottomClass = 'pb-8 pb-md-11 pb-lg-13';
        break;
    }
  }
  return spacingBottomClass;
};
